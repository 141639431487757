import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b1108f0c = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _5f30f092 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _75008d76 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _316af868 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _6a7abe92 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _228a9379 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _bbb5d270 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _d931d854 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _0a448185 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _8720c296 = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _5b674b8f = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _ff4e5c4c = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _08c382d7 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _66e4d62a = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _1a0def4a = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _5f14f536 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _d7703880 = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _1e825ab6 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _3582dc40 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _24dd4811 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _b1108f0c,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _5f30f092,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _75008d76,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _316af868,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _6a7abe92,
    name: "receipt"
  }, {
    path: "/search",
    component: _228a9379,
    name: "search"
  }, {
    path: "/search-booking",
    component: _bbb5d270,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _d931d854,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _0a448185,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _8720c296,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _5b674b8f,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _ff4e5c4c,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _08c382d7,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _66e4d62a,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _1a0def4a,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _5f14f536,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _d7703880,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _1e825ab6,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _3582dc40,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _24dd4811,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
